"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.CheckbookComponent = exports.CheckbookCategory = void 0;
var CheckbookCategory;
(function (CheckbookCategory) {
  CheckbookCategory["maintenance"] = "MAINTENANCE";
  CheckbookCategory["repair"] = "REPAIR";
  CheckbookCategory["update"] = "UPDATE";
  CheckbookCategory["other"] = "OTHER";
})(CheckbookCategory = exports.CheckbookCategory || (exports.CheckbookCategory = {}));
var CheckbookComponent;
(function (CheckbookComponent) {
  CheckbookComponent["mainboard"] = "MAINBOARD";
  CheckbookComponent["comboard"] = "COMBOARD";
  CheckbookComponent["other"] = "OTHER";
})(CheckbookComponent = exports.CheckbookComponent || (exports.CheckbookComponent = {}));