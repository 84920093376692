"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AlkoCulture = void 0;
var AlkoCulture;
(function (AlkoCulture) {
  AlkoCulture["Austria"] = "AT";
  AlkoCulture["Australia"] = "AU";
  AlkoCulture["Czechia"] = "CZ";
  AlkoCulture["Germany"] = "DE";
  AlkoCulture["Denmark"] = "DK";
  AlkoCulture["Spain"] = "ES";
  AlkoCulture["FaroeIslands"] = "FO";
  AlkoCulture["Finland"] = "FI";
  AlkoCulture["France"] = "FR";
  AlkoCulture["GreateBritan"] = "GB";
  AlkoCulture["Croatia"] = "HR";
  AlkoCulture["Hungarian"] = "HU";
  AlkoCulture["Island"] = "IS";
  AlkoCulture["Italy"] = "IT";
  AlkoCulture["Latvia"] = "LV";
  AlkoCulture["Lithuania"] = "LT";
  AlkoCulture["Netherlends"] = "NL";
  AlkoCulture["Norway"] = "NO";
  AlkoCulture["NewZealand"] = "NZ";
  AlkoCulture["Poland"] = "PL";
  AlkoCulture["Serbia"] = "RS";
  AlkoCulture["Romania"] = "RO";
  AlkoCulture["Russia"] = "RU";
  AlkoCulture["Sweden"] = "SE";
  AlkoCulture["Slovenia"] = "SI";
  AlkoCulture["Switzerland"] = "CH";
  AlkoCulture["Slovakia"] = "SK";
  AlkoCulture["Ukraine"] = "UA";
  AlkoCulture["Ukraine2"] = "UK";
  AlkoCulture["Estland"] = "EE";
})(AlkoCulture = exports.AlkoCulture || (exports.AlkoCulture = {}));