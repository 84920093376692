"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AlkoClients = void 0;
var AlkoClients;
(function (AlkoClients) {
  AlkoClients["Intouch3"] = "inTouchApp";
  AlkoClients["Intouch4"] = "inTOUCH4";
  AlkoClients["Webshop"] = "ALKOShop";
  AlkoClients["EndOfLine"] = "EndOfLine";
  AlkoClients["ServiceDashboard"] = "ServiceDashboard";
})(AlkoClients = exports.AlkoClients || (exports.AlkoClients = {}));