"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ThingType = void 0;
var ThingType;
(function (ThingType) {
  ThingType["robolinho"] = "ALKO-ROBOLINHO";
  ThingType["waterwork"] = "ALKO-WATERWORK";
  ThingType["powerhead"] = "ALKO-POWERHEAD";
  ThingType["nonsmart"] = "ALKO-NON-CONNECTED-DEVICE";
  ThingType["tractor"] = "ALKO-TRACTOR";
})(ThingType = exports.ThingType || (exports.ThingType = {}));