"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.SalesStatus = exports.TargetGroup = void 0;
var TargetGroup;
(function (TargetGroup) {
  TargetGroup["customer"] = "Customer";
  TargetGroup["alkoEmployee"] = "ALKOEmployee";
  TargetGroup["servicePartner"] = "ServicePartner";
  TargetGroup["vendor"] = "Vendor";
})(TargetGroup = exports.TargetGroup || (exports.TargetGroup = {}));
var SalesStatus;
(function (SalesStatus) {
  SalesStatus["preSales"] = "presales";
  SalesStatus["sales"] = "sales";
  SalesStatus["afterSales"] = "aftersales";
})(SalesStatus = exports.SalesStatus || (exports.SalesStatus = {}));