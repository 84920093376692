"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.AlkoRoles = void 0;
var AlkoRoles;
(function (AlkoRoles) {
  AlkoRoles["Admin"] = "ADMIN";
  AlkoRoles["AlkoEmployee"] = "ALKOEMPLOYEE";
  AlkoRoles["B2BAccess"] = "B2BACCESS";
  AlkoRoles["B2BFinishedGoods"] = "B2BFINISHEDGOODS";
  AlkoRoles["B2BFinishedGoodsSba"] = "B2BFINISHEDGOODSSBA";
  AlkoRoles["B2BSpareparts"] = "B2BSPAREPARTS";
  AlkoRoles["Betatester"] = "BETATESTER";
  AlkoRoles["Enduser"] = "CUSTOMER";
  AlkoRoles["Dealer"] = "DEALER";
  AlkoRoles["ServiceInternal"] = "SERVICEINTERNAL";
  AlkoRoles["ServicePartner"] = "SERVICEPARTNER";
})(AlkoRoles = exports.AlkoRoles || (exports.AlkoRoles = {}));
;