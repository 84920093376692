"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.ServiceLogCategories = void 0;
/** @deprecated */
var ServiceLogCategories;
(function (ServiceLogCategories) {
  ServiceLogCategories["maintenance"] = "MAINTENANCE";
  ServiceLogCategories["repair"] = "REPAIR";
  ServiceLogCategories["update"] = "UPDATE";
  ServiceLogCategories["other"] = "OTHER";
})(ServiceLogCategories = exports.ServiceLogCategories || (exports.ServiceLogCategories = {}));