"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.DeleteReason = void 0;
var DeleteReason;
(function (DeleteReason) {
  DeleteReason["expired"] = "expired";
  DeleteReason["user"] = "user";
  DeleteReason["device"] = "device";
  DeleteReason["transfered"] = "transfered";
})(DeleteReason = exports.DeleteReason || (exports.DeleteReason = {}));