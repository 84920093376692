"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.OperationStates = exports.RobolinhoModels = void 0;
var RobolinhoModels;
(function (RobolinhoModels) {
  RobolinhoModels["R450"] = "Robolinho450";
  RobolinhoModels["R500"] = "Robolinho500";
  RobolinhoModels["R700"] = "Robolinho700";
  RobolinhoModels["R800"] = "Robolinho700";
  RobolinhoModels["R1150"] = "Robolinho1150";
  RobolinhoModels["R1200"] = "Robolinho1200";
  RobolinhoModels["R2000"] = "Robolinho2000";
})(RobolinhoModels = exports.RobolinhoModels || (exports.RobolinhoModels = {}));
var OperationStates;
(function (OperationStates) {
  OperationStates["working"] = "WORKING";
  OperationStates["idle"] = "IDLE";
  OperationStates["basestation"] = "IDLE_BASE_STATION";
  OperationStates["charging"] = "CHARGING";
  OperationStates["error"] = "ERROR";
})(OperationStates = exports.OperationStates || (exports.OperationStates = {}));