"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.FotaMode = exports.FotaState = void 0;
var FotaState;
(function (FotaState) {
  FotaState[FotaState["updateAvailable"] = 0] = "updateAvailable";
  FotaState[FotaState["inProgress"] = 1] = "inProgress";
  FotaState[FotaState["upToDate"] = 2] = "upToDate";
})(FotaState = exports.FotaState || (exports.FotaState = {}));
var FotaMode;
(function (FotaMode) {
  FotaMode[FotaMode["off"] = 0] = "off";
  FotaMode[FotaMode["once"] = 1] = "once";
  FotaMode[FotaMode["automatic"] = 2] = "automatic";
})(FotaMode = exports.FotaMode || (exports.FotaMode = {}));